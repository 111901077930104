<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="28px"
    height="28px"
    viewBox="0,0,256,256"
  >
    <g transform="translate(51.2,51.2) scale(0.6,0.6)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="#ae4ab1"
        stroke-width="28"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <path
          d="M128,321.71926c-106.98819,0 -193.71926,-86.73107 -193.71926,-193.71926v0c0,-106.98819 86.73107,-193.71926 193.71926,-193.71926h0c106.98819,0 193.71926,86.73107 193.71926,193.71926v0c0,106.98819 -86.73107,193.71926 -193.71926,193.71926z"
          id="shape"
        ></path>
      </g>
      <g
        fill="#ae4ab1"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(10.66667,10.66667)">
          <path
            d="M11,1v2h2v-2zM13,3v2h-2v-2h-7v19h16v-19zM12,8c1.417,0 2.5,1.083 2.5,2.5c0,1.417 -1.083,2.5 -2.5,2.5c-1.417,0 -2.5,-1.083 -2.5,-2.5c0,-1.417 1.083,-2.5 2.5,-2.5zM12,14c2.731,0 5,1.21203 5,2.95703v1.04297h-10v-1.04297c0,-1.745 2.269,-2.95703 5,-2.95703z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "JobHireIcon",
};
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
